import React from 'react';
import { graphql } from 'gatsby';

import { SEO } from 'gatsby-theme-thepuzzlers-intl';
import { injectIntl } from 'gatsby-theme-thepuzzlers-intl';

// Local Components
import { HeadlineSection, SectionSpacer } from 'components';
import { useNavOverlayAnimation } from 'context/NavOverlayAnimationContext';
import {
  useProjectOverviewData,
  useCmsProjectOverviewData,
  ProjectCategory
} from 'sections/projects';

const Projects = ({ data }) => {
  const { isAnimating } = useNavOverlayAnimation();

  // Prismic Projects Data
  const projects = data.projects.projects;

  const { seo, project } = useProjectOverviewData();
  const { header } = useCmsProjectOverviewData();

  if (!isAnimating)
    return (
      <>
        <SEO {...seo} />
        <HeadlineSection
          text={header.currentLanguageData.headline}
          isFullHeight={true}
        />
        <ProjectCategory title={project.title} projectList={projects} />
        <SectionSpacer
          spacing={['75.25%', '46.53%', '33.94%', '23.25%', '24.03%', '18.76%']}
        />
      </>
    );
  return null;
};

export const query = graphql`
  query getPrismicOverviewProjects($prismicLang: String) {
    projects: allPrismicProject(
      filter: { lang: { eq: $prismicLang } }
      sort: { fields: data___date, order: DESC }
    ) {
      totalCount
      projects: nodes {
        lang
        uid
        data {
          category
          location
          year
          size
          name {
            text
          }
        }
      }
    }
  }
`;

export default injectIntl(Projects);
