import React from 'react';

// External Components
import { Section, GridWrapper, Box } from '@thepuzzlers/pieces';
import { NavigationLink, useIntl } from 'gatsby-theme-thepuzzlers-intl';

// Local Components
import { ListTitle, ListText, ListWrapper, RedArrow } from 'components';

export const ProjectCategory = ({ title, projectList }) => {
  return (
    <Section className="project-category">
      <ListTitle className="project-category__title" text={title} />
      <ListWrapper>
        {projectList.map((project) => (
          <ProjectListItem
            key={project.data}
            uid={project.uid}
            data={project.data}
          />
        ))}
      </ListWrapper>
    </Section>
  );
};

const ListLabel = ({ children, sx, props, variant }) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      ...sx
    }}>
    <ListText
      variant={variant}
      // type="p-500-175"
      type="navLinkFooter"
      as="p"
      sx={{
        lineHeight: '125%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: '100%'
      }}
      {...props}>
      {children}
    </ListText>
  </Box>
);

const categoryNames = {
  'Set- und Eventbau': 'Event/Set',
  Ladenbau: 'Shop',
  Messebau: 'Exhibition'
};
const germanCategoryNames = {
  'Set- und Eventbau': 'Event-/Setbau',
  Ladenbau: 'Ladenbau',
  Messebau: 'Messebau'
};

const ProjectListItem = ({
  uid,
  data: { name, location, year, category, size }
}) => {
  const { locale } = useIntl();

  return (
    <NavigationLink
      to={locale === 'en' ? `/projects/${uid}` : `/projekte/${uid}`}
      sx={{
        position: 'relative',
        ':nth-of-type(even) .project-image ': {
          left: '33%'
        },
        ':nth-of-type(odd) .project-image ': {
          left: '36%'
        }
      }}>
      <GridWrapper
        className="projects__item"
        variant="inside.autoColumns"
        sx={{
          gridColumn: ['1/13', '1/13', '1/25', '1/25', '1/25', '1 / span  23'],
          py: ['7.52%', '6.20%', '4.53%', '2.91%', '3.20%', '3.26%'],
          gridRowGap: ['14px', '12px', '15px', 0, 0, 0]
        }}
        //Animation Value
        initial="initial"
        whileHover="hover">
        <ListLabel
          as="h3"
          variant="bold"
          sx={{
            gridColumn: [
              'span 13',
              'span 8',
              'span 13',
              'span 4',
              'span 5',
              'span 5'
            ],
            mb: ['2%', '2.75%', 0, 0, 0, 0],

            gridRow: 1
          }}>
          {name.text}
        </ListLabel>

        {/* Location */}
        <ListLabel
          sx={{
            gridColumn: [
              'span 5',
              'span 5',
              'span 6',
              'span 5',
              'span 5',
              'span 5'
            ],
            textAlign: 'left',
            gridRow: [2, 2, 2, 1, 1, 1]
          }}>
          {location}
        </ListLabel>

        {/* Category */}
        <ListLabel
          sx={{
            gridColumn: [
              'span 5',
              'span 5',
              '15/span 8',
              'span 5',
              'span 4',
              'span 3'
            ],
            gridRow: [2, 2, 1, 1, 1, 1],
            textAlign: ['right', 'right', 'right', 'left', 'left', 'left']
          }}>
          {locale === 'en'
            ? categoryNames[category]
            : germanCategoryNames[category]}
        </ListLabel>

        {/* Size */}
        <ListLabel
          sx={{
            gridColumn: [
              '1/span 7',
              '1/span 6',
              'span 9',
              'span 5',
              'span 5',
              'span 5'
            ],
            textAlign: ['left', 'left', 'center', 'right', 'right', 'right'],
            // justifySelf: [null, null, 'end', 'end', 'end', 'end'],
            gridRow: [3, 3, 2, 1, 1, 1]
            // paddingLeft: '10px'
          }}>
          {size}
        </ListLabel>

        {/* Year */}
        <ListLabel
          sx={{
            gridColumn: [
              '8 / span 3',
              '7/ span 4',
              '19/ span 4',
              'span 3',
              'span 3',
              'span 3'
            ],
            justifySelf: 'end',
            mt: ['1.71%', 0, 0, 0, 0, 0],
            gridRow: [3, 3, 2, 1, 1, 1]
          }}>
          {year}
        </ListLabel>
        {/* </Box> */}
        <RedArrow
          sx={{
            gridRow: ['2 / span 2', '1 / span 2', '1 / span 2', 1, 1],
            gridColumn: [
              '12/13',
              '12/13',
              '23/25',
              '24 / 25',
              '24 / 25',
              '23 / 24'
            ],
            alignSelf: ['end', 'end', 'end', 'center', 'center', 'center'],
            justifySelf: 'end',
            width: ['111%', '73%', '60%', '82%', '72%', '81%']
          }}
        />
      </GridWrapper>
    </NavigationLink>
  );
};
