import { usePageContext } from 'gatsby-theme-thepuzzlers-core';

// TODO: reuse this helper function to get correct language data from the cms entries after all hook-up-with-cms branch is merged
// entries argument must be the array items, so we select them by entries[0],
//but some times we don't need to put the index so we keep the function like this

export const useGetCurrentLanguageCmsData = () => {
  const { locale } = usePageContext();

  const getCurrentLanguageData = (entries) => {
    if (locale === 'de') return entries.entry;
    if (locale === 'en') return entries.translations.en;
  };

  return { getCurrentLanguageData };
};
