import { graphql, useStaticQuery } from 'gatsby';

// helper
import { useGetCurrentLanguageCmsData } from 'sections/helper';

export const useCmsProjectOverviewData = () => {
  const { getCurrentLanguageData } = useGetCurrentLanguageCmsData();

  const { allRestApiPublicPagesProjectEntries } = useStaticQuery(graphql`
    query {
      allRestApiPublicPagesProjectEntries {
        nodes {
          data {
            name
            contentTypes {
              name
              key
              entries {
                entry {
                  headline
                }
                translations {
                  en {
                    headline
                  }
                }
              }
            }
            key
          }
        }
      }
    }
  `);

  const projectPageSectionData =
    allRestApiPublicPagesProjectEntries.nodes[0].data.contentTypes;

  const currentLanguageData = projectPageSectionData.map((sectionData) => {
    return {
      ...sectionData,
      currentLanguageData: getCurrentLanguageData(sectionData.entries[0])
    };
  });

  return {
    header: currentLanguageData[0]
  };
};
